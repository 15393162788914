import mitt from 'mitt';


export type NotificationOpts = {
  title?: string,
  message: string,
  duration?: number,
  type?: 'success' | 'error' | 'warning' | null,
};

export const notifier = mitt<{
  notification: NotificationOpts,
}>();

export function showNotification(opts: NotificationOpts) {
  notifier.emit('notification', opts);
}
